@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

body {
  font-family: 'Ubuntu', sans-serif !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

.js-focus-visible :focus:not([data-focus-visible-added]) {
  outline: none;
  box-shadow: none;
}

.chakra-ui-light {
  background: #EDF2F7;
}

.chakra-ui-dark {
  background: #1A202C;
}

.ce-block__content,
.ce-toolbar__content {
  max-width: 100%;
}

.codex-editor__redactor {
  padding-bottom: 0 !important;
}

.vjs-theme-forest {
  --vjs-theme-forest--primary: #009CA3;
  --vjs-theme-forest--secondary: #fff;

  .vjs-big-play-button {
    transition: .3s ease-in-out;
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODgiIGhlaWdodD0iODgiIHZpZXdCb3g9IjAgMCA4OCA4OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTM1LjIgNTkuNFYyOC42QzM1LjIgMjYuNzk2IDM3LjI2OCAyNS43NCAzOC43MiAyNi44NEw1OS4yNjggNDIuMjRDNjAuNDU2IDQzLjEyIDYwLjQ1NiA0NC44OCA1OS4yNjggNDUuNzZMMzguNzIgNjEuMTZDMzcuMjY4IDYyLjI2IDM1LjIgNjEuMjA0IDM1LjIgNTkuNFoiIGZpbGw9IiMwMDlDQTMiLz4KPHBhdGggZD0iTTQ0IDBDMTkuNzEyIDAgMCAxOS43MTIgMCA0NEMwIDY4LjI4OCAxOS43MTIgODggNDQgODhDNjguMjg4IDg4IDg4IDY4LjI4OCA4OCA0NEM4OCAxOS43MTIgNjguMjg4IDAgNDQgMFpNMzUuMiA1OS40VjI4LjZDMzUuMiAyNi43OTYgMzcuMjY4IDI1Ljc0IDM4LjcyIDI2Ljg0TDU5LjI2OCA0Mi4yNEM2MC40NTYgNDMuMTIgNjAuNDU2IDQ0Ljg4IDU5LjI2OCA0NS43NkwzOC43MiA2MS4xNkMzNy4yNjggNjIuMjYgMzUuMiA2MS4yMDQgMzUuMiA1OS40WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==");
  }

  &.vjs-big-play-button:focus {
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODgiIGhlaWdodD0iODgiIHZpZXdCb3g9IjAgMCA4OCA4OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTM1LjIgNTkuNFYyOC42QzM1LjIgMjYuNzk2IDM3LjI2OCAyNS43NCAzOC43MiAyNi44NEw1OS4yNjggNDIuMjRDNjAuNDU2IDQzLjEyIDYwLjQ1NiA0NC44OCA1OS4yNjggNDUuNzZMMzguNzIgNjEuMTZDMzcuMjY4IDYyLjI2IDM1LjIgNjEuMjA0IDM1LjIgNTkuNFoiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik00NCAwQzE5LjcxMiAwIDAgMTkuNzEyIDAgNDRDMCA2OC4yODggMTkuNzEyIDg4IDQ0IDg4QzY4LjI4OCA4OCA4OCA2OC4yODggODggNDRDODggMTkuNzEyIDY4LjI4OCAwIDQ0IDBaTTM1LjIgNTkuNFYyOC42QzM1LjIgMjYuNzk2IDM3LjI2OCAyNS43NCAzOC43MiAyNi44NEw1OS4yNjggNDIuMjRDNjAuNDU2IDQzLjEyIDYwLjQ1NiA0NC44OCA1OS4yNjggNDUuNzZMMzguNzIgNjEuMTZDMzcuMjY4IDYyLjI2IDM1LjIgNjEuMjA0IDM1LjIgNTkuNFoiIGZpbGw9IiMwMDlDQTMiLz4KPC9zdmc+Cg==");
    filter: opacity(.8);
  }

  &:hover .vjs-big-play-button {
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODgiIGhlaWdodD0iODgiIHZpZXdCb3g9IjAgMCA4OCA4OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTM1LjIgNTkuNFYyOC42QzM1LjIgMjYuNzk2IDM3LjI2OCAyNS43NCAzOC43MiAyNi44NEw1OS4yNjggNDIuMjRDNjAuNDU2IDQzLjEyIDYwLjQ1NiA0NC44OCA1OS4yNjggNDUuNzZMMzguNzIgNjEuMTZDMzcuMjY4IDYyLjI2IDM1LjIgNjEuMjA0IDM1LjIgNTkuNFoiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik00NCAwQzE5LjcxMiAwIDAgMTkuNzEyIDAgNDRDMCA2OC4yODggMTkuNzEyIDg4IDQ0IDg4QzY4LjI4OCA4OCA4OCA2OC4yODggODggNDRDODggMTkuNzEyIDY4LjI4OCAwIDQ0IDBaTTM1LjIgNTkuNFYyOC42QzM1LjIgMjYuNzk2IDM3LjI2OCAyNS43NCAzOC43MiAyNi44NEw1OS4yNjggNDIuMjRDNjAuNDU2IDQzLjEyIDYwLjQ1NiA0NC44OCA1OS4yNjggNDUuNzZMMzguNzIgNjEuMTZDMzcuMjY4IDYyLjI2IDM1LjIgNjEuMjA0IDM1LjIgNTkuNFoiIGZpbGw9IiMwMDlDQTMiLz4KPC9zdmc+Cg==");
  }
}

.video-js {
  .vjs-time-tooltip {
    top: -2.6em;
  }

  .vjs-progress-control:hover .vjs-time-tooltip, .video-js .vjs-progress-control:hover .vjs-progress-holder:focus .vjs-time-tooltip {
    font-size: 1.5em;
  }
}

.vjs-remaining-time-display {
  font-size: 1.2em;
}

.lc,
.ce-block {
  &__header {
    font-weight: bold;
    margin-bottom: 24px;

    h1 { font-size: 48px }
    h2 { font-size: 36px; }
    h3 { font-size: 30px; }
    h4 { font-size: 20px; }
    h5 { font-size: 16px; }
    h6 { font-size: 16px; }
  }

  h1, h2, h3, h4, h5, h6 {
    &.ce-header {
      font-weight: bold;
      margin-bottom: 24px;
      padding: 0;
    }
  }

  h1.ce-header { font-size: 48px }
  h2.ce-header { font-size: 36px; }
  h3.ce-header { font-size: 30px; }
  h4.ce-header { font-size: 20px; }
  h5.ce-header { font-size: 16px; }
  h6.ce-header { font-size: 16px; }

  &__paragraph,
  .ce-paragraph {
    line-height: 1.8em;
    margin: 16px 0;
    font-size: 1.1em;
  }

  &__list {
    margin: 1em 2em;
    font-size: 1.1em;
  }

  .cdx-list__item {
    padding: 0;
  }

  &__quote {
    font-size: 1.1em;
    font-weight: 600;
    margin: 2em;

    blockquote {
      font-style: italic;
      font-weight: 400;
    }
  }
}

label.chakra-checkbox {
  a {
    color: var(--chakra-colors-blue-600);
    cursor: pointer;
    text-decoration: none;

    &:hover {
      color: var(--chakra-colors-blue-500);
    }
  }
}
